<template>
  <div class="cashes-select">
    <div class="card shadow">
      <div class="card-header"><h4>Kassenauswahl - {{ shop.name }}</h4></div>
      <div class="card-body">
        <div class="row row-eq-height" v-for="(cash) in cashes" :key="cash.id">
          <div class="card mb-1">
            <div class="card-body">
              <div class="row row-eq-height align-items-center text-start">
                <div class="col-1" v-if="currentCashId === cash.id"><b-icon-caret-right-fill class="text-success fs-3" /></div>
                <div class="col-1" v-else><b-icon-x-square class="text-light fs-3" /></div>
                <div class="col-6"><span class="fs-3">{{ cash.name }}</span></div>
                <div class="col-5 text-end">
                  <a class="link link-primary fs-3" href="#" v-if="currentCashId !== cash.id"
                     v-on:click="goToCashBooking(cash.id)"
                     v-bind:title="`${cash.name} auswählen und zu den Kassenbuchungen`" >
                    <b-icon-caret-right-square />
                  </a>
                  <a class="link link-success fs-3" href="#" v-else
                     v-on:click="goToCashBooking(cash.id)"
                     v-bind:title="`zu den Kassenbuchungen für ${cash.name}`" >
                    <b-icon-caret-right-square-fill />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { useStore } from '@/use/useStore'
import { SHOP_STORE } from '@/store/constants'
import moment from 'moment'
import { getAllForShopApi } from '@/services/api/cash/api'
import { Cash } from '@/store/modules/cash/interfaces'
import { useRouter } from 'vue-router'
import { getLocalStorage, setLocalStorage } from '@/services/utils/localStorage'

export default defineComponent({
  name: 'CashSelect',
  setup () {
    function getDate (date: Date) {
      return moment(date).format('dddd, d. MMM YYYY')
    }
    function getTime (date: Date) {
      return moment(date).format('LT')
    }

    function getDiff (start: Date) {
      const now = new Date()
      if (moment(start) < moment(now)) {
        return 'aktuell geöffnet'
      }
      return moment(start).fromNow()
    }

    const store = useStore()
    const router = useRouter()
    const shop = computed(() => store.getters[SHOP_STORE.GETTERS.GET_SHOP_DATA])

    const currentCashId = ref(getLocalStorage('currentCashId'))
    function goToCashBooking (id: string) {
      setLocalStorage('currentCashId', id)
      currentCashId.value = id
      router.push({ name: 'CashBooking' })
    }

    const cashes = ref()
    async function getCashes () {
      try {
        const date: Date = new Date()
        const temp: Cash[] = await getAllForShopApi(shop.value ? shop.value.id as string : '')
        if (temp) {
          cashes.value = temp
        }
      } catch (e) {
        console.log(e)
      }
    }
    watch(shop, () => {
      getCashes()
    })

    getCashes()

    return {
      cashes,
      shop,
      currentCashId,
      goToCashBooking,
      getDate,
      getTime,
      getDiff
    }
  }
})
</script>
