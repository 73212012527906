
import { v4 as uuidv4 } from 'uuid'
import { defineComponent, PropType, ref, watch } from 'vue'
import { defineRule, useField, useForm } from 'vee-validate'
import '@/validations'
import { Booking } from '@/store/modules/booking/interfaces'
import { Seller } from '@/store/modules/seller/interfaces'

export default defineComponent({
  name: 'CashBookingForm',
  props: {
    booking: {
      type: Object as PropType<Booking>
    },
    sellers: {
      type: Object as PropType<Seller[]>
    },
    processId: String,
    index: Number
  },
  setup (props, { emit }) {
    const { meta, handleSubmit, resetForm, setFieldValue } = useForm()
    defineRule('seller', (value: string) => {
      if (!props.sellers?.find(o => o.number === Number(value))) return 'Verkäufer-Nr. falsch'
      return true
    })
    const {
      value: sellerNumber,
      errorMessage: sellerNumberError,
      meta: sellerNumberMeta
    } = useField(
      'sellerNumber', {
        required: true,
        numeric: true,
        seller: true
      }, { label: 'Verkäufer-Nr.' })
    const {
      value: amount,
      errorMessage: amountError,
      meta: amountMeta
    } = useField(
      'amount', {
        required: true,
        currency: true
      }, { label: 'Preis in EUR' })

    const submit = handleSubmit(() => {
      const amountString = amount.value as string
      const amountNumber = Number(amountString.replace(',', '.') || amount.value)
      if (props.booking) {
        const bookingSaved: Booking | void = props.booking as Booking
        bookingSaved.amount = amountNumber
        bookingSaved.refId = getSellerId(sellerNumber.value as string)
        emit('BookingSaved', bookingSaved)
      } else {
        const bookingSaved: Booking = {
          id: uuidv4(),
          processId: props.processId || '',
          amount: amountNumber,
          refId: getSellerId(sellerNumber.value as string),
          refType: 'seller',
          type: 'H',
          status: 0,
          createdAt: new Date(),
          updatedAt: new Date()
        }
        emit('BookingSaved', bookingSaved)
      }
      resetForm()
      focusInput()
    })

    function getSellerId (sellerNumber: string) {
      return props.sellers?.find(o => o.number === Number(sellerNumber))?.id || ''
    }

    function getSellerNumber (sellerId: string) {
      return props.sellers?.find(o => o.id === sellerId)?.number || undefined
    }

    function focusInput () {
      const input = document.getElementById('sellerNumber')
      if (input) input.focus()
    }

    watch(props, () => {
      setFieldValue('amount', props.booking?.amount)
      setFieldValue('sellerNumber', getSellerNumber(props.booking?.refId || ''))
    })

    return {
      meta,
      sellerNumber,
      sellerNumberMeta,
      sellerNumberError,
      amount,
      amountMeta,
      amountError,
      submit,
      resetForm
    }
  }
})
