
import { computed, defineComponent, ref, watch } from 'vue'
import { useStore } from '@/use/useStore'
import { SHOP_STORE } from '@/store/constants'
import moment from 'moment'
import { getApi } from '@/services/api/cash/api'
import CashBookingForm from '@/components/shop/cash/BookingForm.vue'
import { Booking, Process } from '@/store/modules/booking/interfaces'
import { v4 as uuidv4 } from 'uuid'
import { getForRefApi } from '@/services/api/seller/api'
import { Seller } from '@/store/modules/seller/interfaces'
import { createApi } from '@/services/api/booking/api'
import { defineRule, useField, useForm } from 'vee-validate'
import { getLocalStorage, setLocalStorage } from '@/services/utils/localStorage'

export default defineComponent({
  name: 'CashBooking',
  components: { CashBookingForm },
  setup () {
    function getDateTimeFormat (date: Date, format: string) {
      return moment(date).format(format)
    }

    function getDiff (start: Date) {
      const now = new Date()
      if (moment(start) < moment(now)) {
        return 'aktuell geöffnet'
      }
      return moment(start).fromNow()
    }

    const store = useStore()
    const shop = computed(() => store.getters[SHOP_STORE.GETTERS.GET_SHOP_DATA])

    const currentCashId = getLocalStorage('currentCashId')
    const cash = ref()
    const sellers = ref<Seller[]>()
    const booking = ref<Booking>()
    const bookings = ref<Booking[]>([])
    const process = ref<Process>()

    async function getCash () {
      try {
        cash.value = await getApi(currentCashId)
        getProcess()
      } catch (e) {
        console.log(e)
      }
    }

    /**
     * get all seller for event
     */
    async function getSellers () {
      try {
        // TODO: get / select eventId as refId
        sellers.value = await getForRefApi('33349743-7a61-40e6-bb38-1c57b7b472se', 'event')
      } catch (e) {
        console.log(e)
      }
    }
    getSellers()

    function getProcess () {
      if (!process.value) {
        if (getLocalStorage('process')) {
          process.value = getLocalStorage('process')
        } else {
          // TODO: get / select eventId as refId
          process.value = {
            id: uuidv4(),
            cashId: cash.value?.id,
            refId: '33349743-7a61-40e6-bb38-1c57b7b472se',
            refType: 'event',
            type: 'deposit',
            from: new Date(),
            to: new Date()
          }
          setLocalStorage('process', process.value)
        }
      }
    }

    function getLocalBookings () {
      bookings.value = getLocalStorage('bookings') ? getLocalStorage('bookings') : []
    }

    function bookingSaved (newBooking: Booking) {
      const index = bookings.value.indexOf(newBooking)
      if (index >= 0) {
        bookings.value[index] = newBooking
      } else {
        if (bookings.value.length === 0 && process.value) process.value.from = newBooking.createdAt
        if (process.value) process.value.to = newBooking.createdAt
        bookings.value.push(newBooking)
      }
      setLocalStorage('bookings', bookings.value, 12 * 60 * 60 * 1000)
      booking.value = undefined
      givenAmount.value = undefined
      resetGivenAmount()
    }
    const indexToDelete = ref<number>()
    function setDeleteIndex (index: number) {
      indexToDelete.value = index
    }
    function deleteBooking () {
      if (indexToDelete.value) {
        bookings.value.splice(indexToDelete.value, 1)
        setLocalStorage('bookings', bookings.value)
      }
      indexToDelete.value = undefined
    }

    function getSellerNumber (sellerId: string) {
      return sellers.value?.find(o => o.id === sellerId)?.number || ''
    }

    async function finishDeposit () {
      // send to API
      try {
        await createApi({
          process: process.value,
          bookings: bookings.value
        })
        // reset process
        localStorage.removeItem('process')
        process.value = undefined
        getProcess()
        // reset bookings
        localStorage.removeItem('bookings')
        bookings.value = []
        getLocalBookings()
      } catch (e) {
        console.log(e)
      }
    }

    function sumAmount () {
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
        bookings.value.map(item => item.amount).reduce((prev, next) => +prev + +next, 0)
      )
    }

    watch(shop, () => {
      if (!cash.value) {
        getCash()
      }
    })
    getCash()
    getLocalBookings()

    function editBooking (bookingEdit: Booking) {
      booking.value = bookingEdit
    }

    // calc for modal deposit
    const restAmount = ref(0)
    const { meta, setFieldValue } = useForm()
    defineRule('checkAmount', (value: string) => {
      const sum = bookings.value.map(item => item.amount).reduce((prev, next) => +prev + +next, 0)
      if (!(sum <= Number(value.replace(',', '.') || value))) return 'Betrag muss größer Summe sein'
      return true
    })
    const {
      value: givenAmount,
      errorMessage: givenAmountError,
      meta: givenAmountMeta,
      resetField: resetGivenAmount
    } = useField(
      'givenAmount', {
        currency: true,
        checkAmount: true
      }, { label: 'Gegeben' })

    watch(givenAmount, () => {
      const sum = bookings.value.map(item => item.amount).reduce((prev, next) => +prev + +next, 0)
      const givenString: string = givenAmount.value ? givenAmount.value as string : '0'
      const give = Number(givenString.replace(',', '.') || givenString)
      restAmount.value = (give - sum) >= 0 ? (give - sum) : 0
    })

    return {
      cash,
      sellers,
      booking,
      bookings,
      process,
      sumAmount,
      bookingSaved,
      finishDeposit,
      getSellerNumber,
      getDateTimeFormat,
      getDiff,
      editBooking,
      setDeleteIndex,
      indexToDelete,
      deleteBooking,
      restAmount,
      givenAmount,
      givenAmountMeta,
      givenAmountError
    }
  }
})
