<template>
  <div class="cash-booking-form">
    <form class="form" v-on:submit.prevent="submit">
      <div class="row row-eq-height g-2 align-items-center">
        <div class="col-12 col-md-4 form-floating h-100">
          <input type="text" class="form-control fs-3" ref="seller" autocomplete="off"
                 v-bind:class="`${sellerNumberMeta.validated ? sellerNumberMeta.valid ? 'is-valid' : 'is-invalid' : ''}`"
                 id="sellerNumber" placeholder="Verkäufer-Nr." v-model="sellerNumber">
          <label for="sellerNumber">Verkäufer-Nr.</label>
          <div class="invalid-feedback" style="text-align: left">
            {{ sellerNumberError }}.
          </div>
        </div>
        <div class="col-12 col-md-4 form-floating h-100">
          <input type="text" class="form-control fs-3" autocomplete="off"
                 v-bind:class="`${amountMeta.validated ? amountMeta.valid ? 'is-valid' : 'is-invalid' : ''}`"
                 id="amount" placeholder="Preis in EUR" v-model="amount">
          <label for="amount">Preis in EUR</label>
          <div class="invalid-feedback" style="text-align: left">
            {{ amountError }}.
          </div>
        </div>
        <div class="col-12 col-md-3 h-100 text-end">
          <button type="submit" class="btn btn-primary mx-2">
            <span class="fs-5"><b-icon-check-lg /></span>
          </button>
          <button type="reset" class="btn btn-danger" v-on:click="resetForm">
            <span class="fs-5"><b-icon-x-lg /></span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { v4 as uuidv4 } from 'uuid'
import { defineComponent, PropType, ref, watch } from 'vue'
import { defineRule, useField, useForm } from 'vee-validate'
import '@/validations'
import { Booking } from '@/store/modules/booking/interfaces'
import { Seller } from '@/store/modules/seller/interfaces'

export default defineComponent({
  name: 'CashBookingForm',
  props: {
    booking: {
      type: Object as PropType<Booking>
    },
    sellers: {
      type: Object as PropType<Seller[]>
    },
    processId: String,
    index: Number
  },
  setup (props, { emit }) {
    const { meta, handleSubmit, resetForm, setFieldValue } = useForm()
    defineRule('seller', (value: string) => {
      if (!props.sellers?.find(o => o.number === Number(value))) return 'Verkäufer-Nr. falsch'
      return true
    })
    const {
      value: sellerNumber,
      errorMessage: sellerNumberError,
      meta: sellerNumberMeta
    } = useField(
      'sellerNumber', {
        required: true,
        numeric: true,
        seller: true
      }, { label: 'Verkäufer-Nr.' })
    const {
      value: amount,
      errorMessage: amountError,
      meta: amountMeta
    } = useField(
      'amount', {
        required: true,
        currency: true
      }, { label: 'Preis in EUR' })

    const submit = handleSubmit(() => {
      const amountString = amount.value as string
      const amountNumber = Number(amountString.replace(',', '.') || amount.value)
      if (props.booking) {
        const bookingSaved: Booking | void = props.booking as Booking
        bookingSaved.amount = amountNumber
        bookingSaved.refId = getSellerId(sellerNumber.value as string)
        emit('BookingSaved', bookingSaved)
      } else {
        const bookingSaved: Booking = {
          id: uuidv4(),
          processId: props.processId || '',
          amount: amountNumber,
          refId: getSellerId(sellerNumber.value as string),
          refType: 'seller',
          type: 'H',
          status: 0,
          createdAt: new Date(),
          updatedAt: new Date()
        }
        emit('BookingSaved', bookingSaved)
      }
      resetForm()
      focusInput()
    })

    function getSellerId (sellerNumber: string) {
      return props.sellers?.find(o => o.number === Number(sellerNumber))?.id || ''
    }

    function getSellerNumber (sellerId: string) {
      return props.sellers?.find(o => o.id === sellerId)?.number || undefined
    }

    function focusInput () {
      const input = document.getElementById('sellerNumber')
      if (input) input.focus()
    }

    watch(props, () => {
      setFieldValue('amount', props.booking?.amount)
      setFieldValue('sellerNumber', getSellerNumber(props.booking?.refId || ''))
    })

    return {
      meta,
      sellerNumber,
      sellerNumberMeta,
      sellerNumberError,
      amount,
      amountMeta,
      amountError,
      submit,
      resetForm
    }
  }
})
</script>

<style scoped>

</style>
