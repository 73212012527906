import { ApiError, getResponseData, handleApiErrors, jkasseApi } from '@/services/api/api'
import { authServices } from '@/services/api/auth/api'
import { Cash } from '@/store/modules/cash/interfaces'

/**
 * get cashes for shop
 * @param id
 */
async function getApi (id: string): Promise<Cash> {
  // get auth-token
  const config = {
    headers: await authServices()
  }
  const urlId = encodeURI(id)
  return jkasseApi.get('/cash/get/' + urlId, config)
    .then((res) => {
      const data = getResponseData(res)
      if (data && data.data && data.data.cashes) {
        return Promise.resolve(data.data.cashes[0])
      }
      return Promise.resolve(undefined)
    })
    .catch((err) => {
      const apiError: ApiError = handleApiErrors(err)
      return Promise.reject(apiError)
    })
}

/**
 * get cashes for shop
 * @param shopId
 */
async function getAllForShopApi (shopId: string): Promise<Cash[]> {
  // get auth-token
  const config = {
    headers: await authServices()
  }
  const urlId = encodeURI(shopId)
  return jkasseApi.get('/cash/getAllForShop/' + urlId, config)
    .then((res) => {
      const data = getResponseData(res)
      if (data && data.data && data.data.cashes) {
        return Promise.resolve(data.data.cashes)
      }
      return Promise.resolve(undefined)
    })
    .catch((err) => {
      const apiError: ApiError = handleApiErrors(err)
      return Promise.reject(apiError)
    })
}

export {
  getApi,
  getAllForShopApi
}
