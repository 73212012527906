<template>

  <h1 v-if="cash">{{ cash.name }}</h1>
  <div class="row row-eq-height align-items-start mb-3">
    <div class="col-12 col-md-8 h-100">
    <div class="card shadow mb-3">
      <div class="card-header">Buchung</div>
      <div class="card-body">
          <CashBookingForm @booking-saved="bookingSaved"
                           :booking="booking" :sellers="sellers" :process-id="process.id" v-if="process"/>
      </div>
    </div>
    </div>
    <div class="col-12 col-md-4 h-100 text-start">
      <div class="card shadow">
        <div class="card-header">Aktueller Kunde</div>
        <div class="card-body">
          <div class="row row-eq-height align-items-center">
            <div class="col-7 fw-bold">Anzahl:</div>
            <div class="col-5 text-end fw-bold">{{ bookings.length }}</div>
          </div>
          <div class="row row-eq-height align-items-center">
            <div class="col-7 fw-bold">Summe:</div>
            <div class="col-5 text-end fw-bold">{{ sumAmount() }}</div>
          </div>
        </div>
        <div class="card-footer text-center">
          <button type="button" class="btn btn-success mx-2" data-bs-toggle="modal" data-bs-target="#deposit"
                  :disabled="bookings.length === 0">
            <span class="fs-5"><b-icon-credit-card />  <b-icon-person-plus-fill /> <b-icon-check-lg /></span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="card shadow" v-if="bookings.length > 0">
    <div class="card-header"><h4>Buchungen</h4></div>
    <div class="card-body reverseorder">
      <div class="row row-eq-height mb-1" v-for="(booking, index) in bookings" :key="booking.id">
        <div class="card">
          <div class="card-body">
            <div class="row row-eq-height align-items-center">
              <div class="col-2 col-md-1">#{{ index + 1 }}</div>
              <div class="col-5 col-md-3 fw-bold">{{ getSellerNumber(booking.refId) }}</div>
              <div class="col-5 col-md-3 fw-bold">{{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                Number(booking.amount)
              ) }}</div>
              <div class="col-7 col-md-3">{{ getDateTimeFormat(booking.updatedAt, 'L, HH:mm') }} Uhr</div>
              <div class="col-5 col-md-2">
                <a type="button" class="text-primary mx-2 fs-3" v-on:click="editBooking(booking)">
                  <span class="fs-5"><b-icon-pencil-fill /></span>
                </a>
                <a type="button" class="text-danger mx-2 fs-3" v-on:click="setDeleteIndex(index)"
                   data-bs-toggle="modal" data-bs-target="#delete">
                  <span class="fs-5"><b-icon-trash-fill /></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal - delete -->
  <div class="modal fade" id="delete" tabindex="-1" aria-labelledby="deleteLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="deleteLabel">Buchungsnummer {{ indexToDelete }} löschen?</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          Wollen Sie die gewählte Buchung endgültig löschen?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Nein</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" v-on:click="deleteBooking">Ja</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal - deposit -->
  <div class="modal fade" id="deposit" tabindex="-1" aria-labelledby="depositLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="depositLabel">
            Bareinzahlung und nächster Kunde?
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p><strong>Soll die Bareinzahlung abgeschlossen und mit dem nächsten Kunden fortgefahren werden?</strong></p>
          <div class="row row-eq-height align-items-center justify-content-center fs-4">
            <div class="col-5 fw-bold">Anzahl:</div>
            <div class="col-7 text-end fw-bold">{{ bookings.length }}</div>
          </div>
          <div class="row row-eq-height align-items-center justify-content-center fs-4">
            <div class="col-5 fw-bold">Summe:</div>
            <div class="col-7 text-end fw-bold">{{ sumAmount() }}</div>
          </div>
          <hr class="dropdown-divider">
          <div class="row row-eq-height align-items-center justify-content-center">
            <div class="col-5 fs-4">Gegeben:</div>
            <div class="col-7 text-end">
              <form class="form" v-on:submit.prevent="submit">
                <div class="form-floating">
                  <input type="text" class="form-control fs-4 text-end" ref="given"
                         v-bind:class="`${givenAmountMeta.validated ? givenAmountMeta.valid ? 'is-valid' : 'is-invalid' : ''}`"
                         id="givenAmount" placeholder="Verkäufer-Nr." v-model="givenAmount">
                  <label for="givenAmount">Gegeben in EUR</label>
                  <div class="invalid-feedback" style="text-align: left">
                    {{ givenAmountError }}.
                  </div>
                </div>
              </form>
            </div>
          </div>
          <hr class="dropdown-divider">
          <div class="row row-eq-height align-items-center justify-content-center fs-4">
            <div class="col-5">Rest:</div>
            <div class="col-5 text-end">
              {{ Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                restAmount
              ) }}
            </div>
          </div>
          <hr class="dropdown-divider">
          <hr class="dropdown-divider">
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Abbruch</button>
          <button type="button" class="btn btn-success" v-on:click="finishDeposit" data-bs-dismiss="modal">
            <b-icon-credit-card />  <b-icon-person-plus-fill /> <b-icon-check-lg />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { useStore } from '@/use/useStore'
import { SHOP_STORE } from '@/store/constants'
import moment from 'moment'
import { getApi } from '@/services/api/cash/api'
import CashBookingForm from '@/components/shop/cash/BookingForm.vue'
import { Booking, Process } from '@/store/modules/booking/interfaces'
import { v4 as uuidv4 } from 'uuid'
import { getForRefApi } from '@/services/api/seller/api'
import { Seller } from '@/store/modules/seller/interfaces'
import { createApi } from '@/services/api/booking/api'
import { defineRule, useField, useForm } from 'vee-validate'
import { getLocalStorage, setLocalStorage } from '@/services/utils/localStorage'

export default defineComponent({
  name: 'CashBooking',
  components: { CashBookingForm },
  setup () {
    function getDateTimeFormat (date: Date, format: string) {
      return moment(date).format(format)
    }

    function getDiff (start: Date) {
      const now = new Date()
      if (moment(start) < moment(now)) {
        return 'aktuell geöffnet'
      }
      return moment(start).fromNow()
    }

    const store = useStore()
    const shop = computed(() => store.getters[SHOP_STORE.GETTERS.GET_SHOP_DATA])

    const currentCashId = getLocalStorage('currentCashId')
    const cash = ref()
    const sellers = ref<Seller[]>()
    const booking = ref<Booking>()
    const bookings = ref<Booking[]>([])
    const process = ref<Process>()

    async function getCash () {
      try {
        cash.value = await getApi(currentCashId)
        getProcess()
      } catch (e) {
        console.log(e)
      }
    }

    /**
     * get all seller for event
     */
    async function getSellers () {
      try {
        // TODO: get / select eventId as refId
        sellers.value = await getForRefApi('33349743-7a61-40e6-bb38-1c57b7b472se', 'event')
      } catch (e) {
        console.log(e)
      }
    }
    getSellers()

    function getProcess () {
      if (!process.value) {
        if (getLocalStorage('process')) {
          process.value = getLocalStorage('process')
        } else {
          // TODO: get / select eventId as refId
          process.value = {
            id: uuidv4(),
            cashId: cash.value?.id,
            refId: '33349743-7a61-40e6-bb38-1c57b7b472se',
            refType: 'event',
            type: 'deposit',
            from: new Date(),
            to: new Date()
          }
          setLocalStorage('process', process.value)
        }
      }
    }

    function getLocalBookings () {
      bookings.value = getLocalStorage('bookings') ? getLocalStorage('bookings') : []
    }

    function bookingSaved (newBooking: Booking) {
      const index = bookings.value.indexOf(newBooking)
      if (index >= 0) {
        bookings.value[index] = newBooking
      } else {
        if (bookings.value.length === 0 && process.value) process.value.from = newBooking.createdAt
        if (process.value) process.value.to = newBooking.createdAt
        bookings.value.push(newBooking)
      }
      setLocalStorage('bookings', bookings.value, 12 * 60 * 60 * 1000)
      booking.value = undefined
      givenAmount.value = undefined
      resetGivenAmount()
    }
    const indexToDelete = ref<number>()
    function setDeleteIndex (index: number) {
      indexToDelete.value = index
    }
    function deleteBooking () {
      if (indexToDelete.value) {
        bookings.value.splice(indexToDelete.value, 1)
        setLocalStorage('bookings', bookings.value)
      }
      indexToDelete.value = undefined
    }

    function getSellerNumber (sellerId: string) {
      return sellers.value?.find(o => o.id === sellerId)?.number || ''
    }

    async function finishDeposit () {
      // send to API
      try {
        await createApi({
          process: process.value,
          bookings: bookings.value
        })
        // reset process
        localStorage.removeItem('process')
        process.value = undefined
        getProcess()
        // reset bookings
        localStorage.removeItem('bookings')
        bookings.value = []
        getLocalBookings()
      } catch (e) {
        console.log(e)
      }
    }

    function sumAmount () {
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
        bookings.value.map(item => item.amount).reduce((prev, next) => +prev + +next, 0)
      )
    }

    watch(shop, () => {
      if (!cash.value) {
        getCash()
      }
    })
    getCash()
    getLocalBookings()

    function editBooking (bookingEdit: Booking) {
      booking.value = bookingEdit
    }

    // calc for modal deposit
    const restAmount = ref(0)
    const { meta, setFieldValue } = useForm()
    defineRule('checkAmount', (value: string) => {
      const sum = bookings.value.map(item => item.amount).reduce((prev, next) => +prev + +next, 0)
      if (!(sum <= Number(value.replace(',', '.') || value))) return 'Betrag muss größer Summe sein'
      return true
    })
    const {
      value: givenAmount,
      errorMessage: givenAmountError,
      meta: givenAmountMeta,
      resetField: resetGivenAmount
    } = useField(
      'givenAmount', {
        currency: true,
        checkAmount: true
      }, { label: 'Gegeben' })

    watch(givenAmount, () => {
      const sum = bookings.value.map(item => item.amount).reduce((prev, next) => +prev + +next, 0)
      const givenString: string = givenAmount.value ? givenAmount.value as string : '0'
      const give = Number(givenString.replace(',', '.') || givenString)
      restAmount.value = (give - sum) >= 0 ? (give - sum) : 0
    })

    return {
      cash,
      sellers,
      booking,
      bookings,
      process,
      sumAmount,
      bookingSaved,
      finishDeposit,
      getSellerNumber,
      getDateTimeFormat,
      getDiff,
      editBooking,
      setDeleteIndex,
      indexToDelete,
      deleteBooking,
      restAmount,
      givenAmount,
      givenAmountMeta,
      givenAmountError
    }
  }
})
</script>
<style>
.reverseorder {
  display: flex;
  flex-direction: column-reverse;
}
</style>
