
import { computed, defineComponent, ref, watch } from 'vue'
import { useStore } from '@/use/useStore'
import { SHOP_STORE } from '@/store/constants'
import moment from 'moment'
import { getAllForShopApi } from '@/services/api/cash/api'
import { Cash } from '@/store/modules/cash/interfaces'
import { useRouter } from 'vue-router'
import { getLocalStorage, setLocalStorage } from '@/services/utils/localStorage'

export default defineComponent({
  name: 'CashSelect',
  setup () {
    function getDate (date: Date) {
      return moment(date).format('dddd, d. MMM YYYY')
    }
    function getTime (date: Date) {
      return moment(date).format('LT')
    }

    function getDiff (start: Date) {
      const now = new Date()
      if (moment(start) < moment(now)) {
        return 'aktuell geöffnet'
      }
      return moment(start).fromNow()
    }

    const store = useStore()
    const router = useRouter()
    const shop = computed(() => store.getters[SHOP_STORE.GETTERS.GET_SHOP_DATA])

    const currentCashId = ref(getLocalStorage('currentCashId'))
    function goToCashBooking (id: string) {
      setLocalStorage('currentCashId', id)
      currentCashId.value = id
      router.push({ name: 'CashBooking' })
    }

    const cashes = ref()
    async function getCashes () {
      try {
        const date: Date = new Date()
        const temp: Cash[] = await getAllForShopApi(shop.value ? shop.value.id as string : '')
        if (temp) {
          cashes.value = temp
        }
      } catch (e) {
        console.log(e)
      }
    }
    watch(shop, () => {
      getCashes()
    })

    getCashes()

    return {
      cashes,
      shop,
      currentCashId,
      goToCashBooking,
      getDate,
      getTime,
      getDiff
    }
  }
})
